<template>
  <v-app id="billy">
    <div class="app">
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>

      <v-app-bar app dense dark class="primary white--text">

        <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="white--text"></v-app-bar-nav-icon>

        <v-toolbar-title>{{ titleName }}</v-toolbar-title>
        <v-spacer></v-spacer>

  <!--Buttoms-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
<!--              :disabled="!user_info || user_info.name === 'anonymous_user'"-->
              <v-btn
                @click="changeLang()"
                small
                icon
                class="ma-1 white--text"
              >
                <v-icon>mdi-translate</v-icon>
              </v-btn>
            </div>
          </template>
          <span> {{ $i18n.locale === 'ua' ? 'en' : 'ua' }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
<!--              :disabled="!user_info || user_info.name === 'anonymous_user'"-->
              <v-btn
                  @click="changeTheme()"
                  small
                  icon
                  class="ma-1 white--text"
              >
                <v-icon>{{ themeIcon }}</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ $vuetify.theme.dark ? 'light' : 'dark' }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn small icon class="ma-1 white--text" v-bind:href="anonymCheck.login_link">
                <v-icon>{{ anonymCheck.login_icon }}</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ anonymCheck.login_tooltip }}</span>
        </v-tooltip>

      </v-app-bar>

      <v-navigation-drawer app v-model="drawer">
        <v-row dense class="d-flex ma-3">

          <v-col cols="4">
            <v-avatar class="primary" size="72">
<!--                <v-img v-if="user_info.avatar === 'avatars/billy.png'" src="../src/assets/billy.png"></v-img>-->
<!--                <v-img v-else-if="user_info.avatar === 'avatars/billy_g.png'" src="../src/assets/billy_g.png"></v-img>-->
<!--                <v-img v-else src="../src/assets/anonym.png"></v-img>-->
              <v-img :src=avatarImg></v-img>
            </v-avatar>
          </v-col>
          <v-col class="mx-2">
            <span class="text-caption mb-2">{{ user_info.name }}</span><br/>
            <v-divider></v-divider>

            <span class="text-caption mb-2">{{ user_info.first_name }} {{ user_info.last_name }}</span><br/>
            <span v-if="user_info.role.is_admin" class="text-caption mb-2">Admin</span>
            <span v-else-if="user_info.role.is_manager" class="text-caption mb-2">Manager</span>
            <span v-else-if="user_info.role.is_counter" class="text-caption mb-2">Counter</span>
            <span v-else-if="user_info.role.is_support" class="text-caption mb-2">TECH Support</span>
            <span v-else-if="user_info.role.is_sim_support" class="text-caption mb-2">SIM Support</span>
            <span v-else-if="user_info.role.is_customer" class="text-caption mb-2">Customer</span>
            <span v-else class="text-caption mb-2">No Role</span>
          </v-col>

        </v-row>
        <v-divider></v-divider>

      <!--<Menu></Menu>-->
        <v-list dense>

        <!--Dashboard-->
          <v-list-group
            :value="false"
            no-action
            prepend-icon="mdi-chart-bar"
            v-if="user_info.role.is_admin"
          >
<!--v-if="permissions.dashboard"-->

            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ $t('menu.dashboard.name') }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item to="dash_servers">
              <v-list-item-title>{{ $t('menu.dashboard.servers') }}</v-list-item-title>
              <v-icon>mdi-chemical-weapon</v-icon>
            </v-list-item>
            <v-list-item to="dash_payments">
              <v-list-item-title>{{ $t('menu.dashboard.payments') }}</v-list-item-title>
              <v-icon>mdi-piggy-bank-outline</v-icon>
            </v-list-item>
            <v-list-item to="dash_tickets">
              <v-list-item-title>{{ $t('menu.dashboard.tickets') }}</v-list-item-title>
              <v-icon>mdi-timeline-clock-outline</v-icon>
            </v-list-item>

          </v-list-group>

        <!--Main-->
          <v-list-group
            :value="false"
            no-action
            prepend-icon="mdi-monitor-dashboard"
            v-if="user_info.role.is_admin || user_info.role.is_manager || user_info.role.is_counter || user_info.role.is_sim_support"
          >
<!--v-if="permissions.main"-->

            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ $t('menu.main.name') }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item to="quantity" v-if="!user_info.role.is_sim_support">
              <v-list-item-title>{{ $t('menu.main.quantity') }}</v-list-item-title>
              <v-icon>mdi-compare</v-icon>
            </v-list-item>
            <v-list-item to="summary">
              <v-list-item-title>{{ $t('menu.main.summary') }}</v-list-item-title>
              <v-icon>mdi-file-compare</v-icon>
            </v-list-item>
            <v-list-item to="balances">
              <v-list-item-title>{{ $t('menu.main.balance') }}</v-list-item-title>
              <v-icon>mdi-scale-balance</v-icon>
            </v-list-item>
            <v-list-item to="tools" v-if="!user_info.role.is_sim_support">
              <v-list-item-title>{{ $t('menu.main.tools') }}</v-list-item-title>
              <v-icon>mdi-hammer-wrench</v-icon>
            </v-list-item>
            <v-list-item to="orders">
              <v-list-item-title>{{ $t('menu.main.orders') }}</v-list-item-title>
              <v-icon>mdi-book-multiple</v-icon>
            </v-list-item>
            <v-list-item to="acts">
              <v-list-item-title>{{ $t('menu.main.acts') }}</v-list-item-title>
              <v-icon>mdi-book-multiple-outline</v-icon>
            </v-list-item>
            <v-list-item to="sims">
              <v-list-item-title>{{ $t('menu.main.sims') }}</v-list-item-title>
              <v-icon>mdi-sim-outline</v-icon>
            </v-list-item>
            <v-list-item to="search">
              <v-list-item-title>{{ $t('menu.main.search') }}</v-list-item-title>
              <v-icon>mdi-table-search</v-icon>
            </v-list-item>

          </v-list-group>

        <!--Settings-->
          <v-list-group
            :value="false"
            no-action
            prepend-icon="mdi-cog-outline"
            v-if="user_info.role.is_admin"
          >

            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ $t('menu.settings.name') }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item to="billings">
              <v-list-item-title>{{ $t('menu.settings.billings') }}</v-list-item-title>
              <v-icon>mdi-circle-multiple-outline</v-icon>
            </v-list-item>
            <v-list-item to="companies">
              <v-list-item-title>{{ $t('menu.settings.companies') }}</v-list-item-title>
              <v-icon>mdi-store</v-icon>
            </v-list-item>
            <v-list-item to="contracts">
              <v-list-item-title>{{ $t('menu.settings.contracts') }}</v-list-item-title>
              <v-icon>mdi-text-box-outline</v-icon>
            </v-list-item>
            <v-list-item to="contracts_ex">
              <v-list-item-title>{{ $t('menu.settings.ex_contracts') }}</v-list-item-title>
              <v-icon>mdi-text-box-remove-outline</v-icon>
            </v-list-item>
            <v-list-item to="contacts">
              <v-list-item-title>{{ $t('menu.settings.contacts') }}</v-list-item-title>
              <v-icon>mdi-card-account-mail-outline</v-icon>
            </v-list-item>
            <v-list-item to="staff">
              <v-list-item-title>{{ $t('menu.settings.staff') }}</v-list-item-title>
              <v-icon>mdi-account-group-outline</v-icon>
            </v-list-item>

          </v-list-group>

        <!--Tickets-->
          <v-list-group
            :value="false"
            no-action
            prepend-icon="mdi-order-bool-ascending-variant"
            v-if="user_info.name !== 'anonymous_user'"
          >

            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ $t('menu.tickets.name') }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item to="tickets_init" v-if="user_info.role.is_admin">
              <v-list-item-title>{{ $t('menu.tickets.init') }}</v-list-item-title>
              <v-icon>mdi-calendar-start-outline</v-icon>
            </v-list-item>

            <v-list-item to="tickets_state" v-if="user_info.name !== 'anonymous_user'">
              <v-list-item-title>{{ $t('menu.tickets.state') }}</v-list-item-title>
              <v-icon>mdi-calendar-check-outline</v-icon>
            </v-list-item>

<!--            <v-list-item to="tickets_service">-->
<!--              <v-list-item-title>{{ $t('menu.tickets.service') }}</v-list-item-title>-->
<!--              <v-icon>mdi-calendar-question-outline</v-icon>-->
<!--            </v-list-item>-->

<!--            <v-list-item disabled to="tickets_montage">-->
<!--              <v-list-item-title>{{ $t('menu.tickets.montage') }}</v-list-item-title>-->
<!--              <v-icon>mdi-calendar-import-outline</v-icon>-->
<!--            </v-list-item>-->

<!--            <v-list-item disabled to="tickets_repair">-->
<!--              <v-list-item-title>{{ $t('menu.tickets.repair') }}</v-list-item-title>-->
<!--              <v-icon>mdi-calendar-filter-outline</v-icon>-->
<!--            </v-list-item>-->

          </v-list-group>

        <!--Flask-->
          <v-list-group
            :value="false"
            no-action
            prepend-icon="mdi-flask-outline"
            v-if="user_info.name !== 'anonymous_user'"
          >
<!--            v-if="user_info.role.is_admin || user_info.role.is_counter || user_info.role.is_sim_support || user_info.role.is_support"-->

            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ $t('menu.flask.name') }}</v-list-item-title>
              </v-list-item-content>
            </template>

<!--            <v-list-item to="sims">-->
<!--              <v-list-item-title>{{ $t('menu.flask.sims') }}</v-list-item-title>-->
<!--              <v-icon>mdi-sim-outline</v-icon>-->
<!--            </v-list-item>-->


            <v-list-item to="tickets_repair">
              <v-list-item-title>{{ $t('menu.tickets.repair') }}</v-list-item-title>
<!--              <v-icon>mdi-bicycle-penny-farthing</v-icon>-->
              <v-icon>mdi-biohazard</v-icon>
            </v-list-item>

            <v-list-item to="repair_devices">
              <v-list-item-title>{{ $t('menu.tickets.devices') }}</v-list-item-title>
              <v-icon>mdi-bug-outline</v-icon>
            </v-list-item>

            <v-list-item to="repair_accounting">
              <v-list-item-title>{{ $t('menu.tickets.accounting') }}</v-list-item-title>
              <v-icon>mdi-arrow-decision-auto-outline</v-icon>
            </v-list-item>

<!--            <v-list-item to="tickets_service">-->
<!--              <v-list-item-title>{{ $t('menu.tickets.service') }}</v-list-item-title>-->
<!--              <v-icon>mdi-calendar-question-outline</v-icon>-->
<!--            </v-list-item>-->

<!--            <v-list-item to="qr">-->
<!--              <v-list-item-title>{{ $t('menu.flask.qr') }}</v-list-item-title>-->
<!--              <v-icon>mdi-qrcode-scan</v-icon>-->
<!--            </v-list-item>-->

<!--            <v-list-item to="permissions">-->
<!--              <v-list-item-title>{{ $t('menu.flask.permissions') }}</v-list-item-title>-->
<!--              <v-icon>mdi-alien-outline</v-icon>-->
<!--            </v-list-item>-->

          </v-list-group>

        </v-list>

      </v-navigation-drawer>

      <v-main>
        <v-container fluid>
          <router-view @updateUserInfo="userInfoUpdate"></router-view>
        </v-container>
      </v-main>

      <v-footer app dark class="primary white--text">
        <span>
          <a href="/" style="color: white; text-decoration: none">
            'Billy Boy' {{ app_version }}
          </a>
        </span>
        <span class="ml-1 hidden-sm-and-down">({{ $t('main.sign') }})</span>
        <v-spacer></v-spacer>

        <span>{{ $t('main.micro') }}</span>

          <v-tooltip v-if="auth" top>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"

                class="ml-1 hidden-xs-only"
              >
<!--        <span v-if="auth" class="ml-1 hidden-xs-only">-->
                <a @click="docDownload" style="color: white; text-decoration: none">© 2021-{{ new Date().getFullYear() }}</a>
              </span>
            </template>
            <span>{{ this.doc_file_name }}</span>
          </v-tooltip>

        <span v-else class="ml-1 hidden-xs-only">© 2021-{{ new Date().getFullYear() }}</span>

    </v-footer>

    </div>
  </v-app>
</template>

<script>
//  import Menu from "@/views/Menu";
  import json from '../package.json'

  export default {
    name: 'App',
//    components: {Menu},

    data() {
      return {
        drawer: false,
        user_lang: 'en',
        user_dark_theme: false,

        app_version: 'v' + json['version'] + 'β',  // α β
        doc_file_name: json['doc_file'],

        auth: false,
        user_role: {},
        permissions: {},

        user_info: {
          'name': 'anonymous_user',
          'first_name': 'Anonymous',
          'last_name': 'User',
          'avatar': 'anonym.png',

          'role': {
            'is_admin': false,
            'is_counter': false,
            'is_customer': false,
            'is_manager': false,
            'is_support': false,
            'is_sim_support': false,
          },
        },

        overlay: false,
      }
    },

    created() {
      this.userInfoUpdate();

      this.$i18n.locale = this.user_lang;
      this.$vuetify.theme.dark = this.user_dark_theme;
    },

    methods: {
      userInfoUpdate() {
        if (localStorage.getItem('user_info')) {
          this.user_info = JSON.parse(localStorage.user_info)
        }

        if (localStorage.getItem('user_lang')) {
          this.user_lang = localStorage.user_lang
        }

        if (localStorage.getItem('user_dark_theme')) {
          this.user_dark_theme = JSON.parse(localStorage.user_dark_theme)
        }

        if (localStorage.getItem('auth')) {
          this.auth = JSON.parse(localStorage.auth)
        }

//        if (sessionStorage.getItem('session_key')) {
//          this.session_key = sessionStorage.session_key
//        }

      },

      changeLang() {
        this.$i18n.locale = this.$i18n.locale === 'ua' ? 'en' : 'ua';
        localStorage.setItem('user_lang', this.$i18n.locale)
      },

      changeTheme() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        localStorage.setItem('user_dark_theme', this.$vuetify.theme.dark)
      },

      docDownload() {
//          window.open(require('../src/assets/BillyBoy 0.3_v0.13.23β.pdf'), '_blank')

        let self = this;
        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'core_api/get_billy_doc/',
          responseType: 'blob',

          params: {},
          data: {
              file_name: self.doc_file_name,

              action: 'billy_doc get',
              method: 'get billy_doc',

          },
        })
        .then(function (response){
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');

          link.href = url;
          link.setAttribute('download', 'BillyBoy ' + self.doc_file_name + '.pdf');
          document.body.appendChild(link);
          link.click();

          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      djangoServerPort() {
        console.log('process.env.NODE_ENV:', process.env.NODE_ENV);

        switch (process.env.NODE_ENV) {
          // case 'production': return '/productionEnv/'
          case 'development': return ':8000'
          default: return ''
        }
      }

    },

    computed: {
      avatarImg() {
        // TODO axios need!
        let path = location.protocol + '//' + location.hostname + this.djangoServerPort();

        return path + '/core_api/get_avatar_img/' + this.user_info.avatar
      },

      themeIcon() {
        if (this.$vuetify.theme.dark) {
          return 'mdi-weather-sunny'
        } else {
          return 'mdi-weather-night'
        }
      },

      anonymCheck() {
//        if (this.user_info.name === 'anonymous_user') {
        if (this.auth === false) {
          return {
            login_link: '/login',
            login_tooltip: 'login',
            login_icon: 'mdi-account-arrow-left-outline'
          }
        } else {
          return {
            login_link: '/logout',
            login_tooltip: 'logout',
            login_icon: 'mdi-account-arrow-right-outline'
          }
        }

      },

      titleName() {
        return this.$i18n.t(this.$route.meta.i18)
      }

    },

    watch: {
//      '$i18n.locale'() {
//            document.title = this.$i18n.t(this.$route.meta.title)
//        },
//      '$route': {
//        handler(to) {
//           document.title = this.$i18n.t(to.meta.title)
//        },
//        immediate: true
//      }
    },

  }
</script>

<style scoped>

</style>
